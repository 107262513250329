import { Box, Container, Grid, Typography } from '@mui/material'
import Markdown from 'react-markdown'
import LanguageSwitcher from './LanguageSwitcher'

import { useEffect, useState } from 'react'
import { fetchArticle } from '../Api'
import { type Article } from '../types'

export default function ArticleContent(
	props: Readonly<{
		qrCodeId: string
		preferredLanguage: [string, string[]]
	}>,
) {
	const { qrCodeId, preferredLanguage } = props

	const [selectedLanguage, setSelectedLanguage] = useState(preferredLanguage[0])
	const [article, setArticle] = useState<Article>()

	useEffect(() => {
		fetchArticle(qrCodeId, selectedLanguage)
			.then((response) => {
				setArticle(response)
			})
			.catch((error) => {
				console.error(error)
			})
	}, [qrCodeId, selectedLanguage])

	if (article) {
		return (
			<Box
				sx={{
					maxWidth: '100vw',
					overflowX: 'hidden',
					paddingTop: 2,
					paddingBottom: 2,
				}}
			>
				<Grid container spacing={2}>
					{article.title && (
						<Grid item xs={12}>
							<Container>
								<Typography variant="h3" align="center">
									{article.title}
								</Typography>
							</Container>
						</Grid>
					)}
					<Grid item xs={12}>
						<Container>
							<Markdown>{article.content ?? ''}</Markdown>
						</Container>
					</Grid>
				</Grid>
				{selectedLanguage !== 'all' && preferredLanguage[1].length > 1 && (
					<LanguageSwitcher
						locales={props.preferredLanguage[1]}
						setSelectedLanguage={setSelectedLanguage}
					/>
				)}
			</Box>
		)
	}

	return <></>
}
