import React from 'react'
import ReactDOM from 'react-dom/client'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import './index.css'
import NotFoundPage from './404'
import App from './App'

const router = createBrowserRouter([
	{
		path: '/exhibit/:id',
		element: <App />,
	},
	{
		path: '*',
		element: <NotFoundPage />,
	},
])

const root = ReactDOM.createRoot(document.getElementById('root')!)
root.render(
	<React.StrictMode>
		<RouterProvider router={router} />
	</React.StrictMode>,
)
