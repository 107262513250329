import { Box, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const Footer: React.FC = () => {
	const currentYear = new Date().getFullYear()

	return (
		<Box
			sx={{
				backgroundColor: '#f5f5f5',
				paddingTop: '1rem',
				textAlign: 'center',
				position: 'relative',
				bottom: 0,
				left: 0,
				width: '100%',
			}}
		>
			<Box>
				<Link to="https://okultis.com/contact">
					<img
						src="https://okultis.com/wp-content/uploads/2020/12/okultis-ss.png"
						alt="Okultis | Software & Systems"
						style={{ maxWidth: '160px' }}
					/>
				</Link>
			</Box>
			<Typography variant="caption" color="textSecondary">
				&copy; 2023-{currentYear}
			</Typography>
		</Box>
	)
}

export default Footer
