import TranslateIcon from '@mui/icons-material/Translate'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import { styled } from '@mui/material/styles'
import localStorage from '../browserStorage'
import FlagIcon from './FlagIcon'
import { useState } from 'react'

const StyledSpeedDial = styled(SpeedDial)(({ theme }) => ({
	position: 'absolute',
	'&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
		bottom: theme.spacing(2),
		right: theme.spacing(2),
	},
}))

export default function LanguageSwitcher(
	props: Readonly<{
		locales: string[]
		setSelectedLanguage: (language: string) => void
	}>,
) {
	const [open, setOpen] = useState(false)

	const handleOpen = () => {
		setOpen(true)
	}

	const handleClose = () => {
		setOpen(false)
	}

	return (
		<StyledSpeedDial
			ariaLabel="Language switcher"
			hidden={false}
			icon={<TranslateIcon />}
			direction={'up'}
			FabProps={{
				size: 'small',
			}}
			sx={{
				position: 'fixed',
				bottom: '12px',
				right: '12px',
			}}
			open={open}
			onOpen={handleOpen}
			onClose={handleClose}
		>
			{props.locales.map((locale) => (
				<SpeedDialAction
					key={locale}
					icon={<FlagIcon countryCode={locale} />}
					tooltipTitle={locale}
					tooltipOpen={false}
					onClick={(e) => {
						e.stopPropagation()
						localStorage.setSelectedLanguage(locale)
						props.setSelectedLanguage(locale)
						handleClose()
					}}
				/>
			))}
		</StyledSpeedDial>
	)
}
