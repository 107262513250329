class BrowserStorage {
	getSelectedLanguage(): string | undefined {
		return localStorage.getItem('selectedLanguage') ?? undefined
	}

	setSelectedLanguage(language: string): void {
		localStorage.setItem('selectedLanguage', language)
	}
}

export default new BrowserStorage()
