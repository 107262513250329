import { Alert, AlertTitle, CircularProgress, Container } from '@mui/material'
import { useParams } from 'react-router-dom'
import useAsync from 'react-use/lib/useAsync'
import { fetchArticleLocales } from '../Api'
import browserStorage from '../browserStorage'
import ArticleContent from './ArticleContent'

const CenteredContainer = (props: any) => (
	<Container
		sx={{
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
			minHeight: '100vh',
		}}
	>
		{props.children}
	</Container>
)

export const ArticlePage = () => {
	const { id: qrCodeId } = useParams()

	const {
		value: prefferedLanguage,
		error,
		loading,
	} = useAsync(async () => {
		const r = await fetchArticleLocales(qrCodeId!)
		const selectedLanguage = browserStorage.getSelectedLanguage()
		let languages: [string, string[]]

		if (!r.locales) languages = ['all', []]

		languages =
			selectedLanguage && r.locales.includes(selectedLanguage)
				? [selectedLanguage, r.locales]
				: [r.locales[0], r.locales]

		return languages
	})

	if (loading)
		return (
			<CenteredContainer>
				<CircularProgress />
			</CenteredContainer>
		)

	if (error)
		return error.message === 'Not Found' ? (
			<CenteredContainer>
				<Alert severity="warning">
					<AlertTitle>Warning</AlertTitle>
					Seems like the page does not exist <br /> <strong>Details: </strong>
					{error.message}
				</Alert>
			</CenteredContainer>
		) : (
			<CenteredContainer>
				<Alert severity="error">
					<AlertTitle>Error</AlertTitle>
					Something unexpected happened. Contact administrator <br />{' '}
					<strong>Details: </strong>
					{error.message}
				</Alert>
			</CenteredContainer>
		)

	if (qrCodeId && prefferedLanguage)
		return (
			<ArticleContent
				qrCodeId={qrCodeId}
				preferredLanguage={prefferedLanguage}
			/>
		)

	return (
		<CenteredContainer>
			<Alert severity="info">
				<AlertTitle>Info</AlertTitle>
				<strong>Exhibit does not exist.</strong> Are you sure you have the right
				link?
			</Alert>
		</CenteredContainer>
	)
}

export default ArticlePage
