import React from 'react'
import { Button, Container, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const NotFoundPage = () => (
	<div
		style={{
			backgroundColor: 'black',
			height: '100vh',
			display: 'flex',
			alignItems: 'center',
			justifyContent: 'center',
		}}
	>
		<Container maxWidth="sm">
			<Typography variant="h3" component="h1" align="center" color="white">
				404 | Stranica ne postoji
			</Typography>
			<Button
				variant="contained"
				component={Link}
				to="https://okultis.com/contact"
				color="primary"
				fullWidth
				sx={{ marginY: '2rem' }}
			>
				Kontaktirajte nas
			</Button>
		</Container>
	</div>
)

export default NotFoundPage
