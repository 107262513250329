import { type Article } from './types'

export async function fetchArticle(
	qrCodeId: string,
	language: string,
): Promise<Article> {
	return fetch('/api/v1/article', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			qrCodeId,
			language,
		}),
	}).then(async (response) => {
		if (!response.ok) throw new Error(response.statusText)
		return response.json() as Promise<Article>
	})
}

export async function fetchArticleLocales(qrCodeId: string): Promise<{
	locales: string[]
}> {
	return fetch('/api/v1/article/locales', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		body: JSON.stringify({
			qrCodeId,
		}),
	}).then(async (response) => {
		if (!response.ok) throw new Error(response.statusText)
		return response.json() as Promise<{ locales: string[] }>
	})
}
