import { Box } from '@mui/material'
import Footer from './Footer'
import ArticlePage from './components/ArticlePage'

function App() {
	return (
		<Box
			display="flex"
			minHeight={'100vh'}
			flexDirection="column"
			alignItems="center"
			padding={0}
			margin={0}
		>
			<ArticlePage />
			<div style={{ flex: 1 }} />
			<Footer />
		</Box>
	)
}

export default App
